export const faq = [
  {
    q: "Is sf2Pro similar with DingTalk and Feishu?",
    a: "sf2Pro focuses on Service industries. Besides facilitating collaboration, sf2Pro provides various functions to deal with service pain points (e.g. lessen misunderstandings or avoid Demand-Supply conflicts).",
  },
  {
    q: "Is sf2Pro only applicable for contracting parties?",
    a: "Not only. sf2Pro can serve teams within the organizations, such as the shared service teams. The internal service teams could be treated as the internal Supply parties.",
  },
  {
    q: "Does sf2Pro provide custom services?",
    a: "sf2Pro provides flexible configs for studio Admins to manage their custom requriements. Besides, sf2Pro provides extra services for VIP studios, e.g., to define custom objectives and measures. However, sf2Pro has no plan to provide custom developments on specific studios.",
  },
  {
    q: "On sf2Pro Man-day, is it true that if an Admin paid 300 man-days, her/his studio will be entitled to run 300 days?",
    a: "It depends on number of users in the studio. For example, if there are 2 users in the concerned studio, relevant studio balance will run up in 150 days; if there are 3 users, the studio balance will run up in 100 days. In addition, sf2Pro provides some promotional benefits from time to time, which may increase the available man-days. For more details, please refer to sf2Pro Pricing.",
  },
  {
    q: "Does sf2Pro provide English version? Could it be used abroad?",
    a: "sf2Pro supports English layouts. Yet the user registration requires a mobile number in mainland China. sf2Pro services are operated on country base and an international version will be released in the near future.",
  },
  {
    q: "Could sf2Pro studio be used for ever?",
    a: "sf2Pro studio is always available before the studio balance is consumed up. After Admin hadn't renewed a studio for a long time, sf2Pro would suspend the studio operation or restrict the access to that studio. For such case if deteriorated further, sf2Pro would archive the studio data and the data would be deleted physically three years later.",
  },
  {
    q: "Are studio data safe in sf2Pro? Are they mixed with other studios?",
    a: "sf2Pro isolates data physically from design point of view. Users don't have to worry the studio data are mixed or accessed by other studio users. Users may check more details in document - sf2Pro Privacy Protection Agreement. Furthermore, sf2Pro hosts services on hardwares of the well known cloud providers which are supposed to comply with the highest data security standards.",
  },
];
